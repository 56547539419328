<template>
  <div class="home-box">
    <!-- 查询区域 -->

    <!-- table区域-begin -->
    <div class="form-box">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px" v-loading="loading">
        <div>
          <div class="el-dialog-header-box">
            <div class="dialog-title-block"></div>
            <span class="dialog-title-text">提现余额</span>
          </div>
          <el-divider />
        </div>
        <div @click="balanceInfo(userInfo.id)">
          <el-descriptions :column="2" >
            <el-descriptions-item label="可提现金额">￥{{userInfo.balance || 0}}</el-descriptions-item>
            <el-descriptions-item label="累计金额">￥{{userInfo.total_balance || 0}}</el-descriptions-item>
            <el-descriptions-item label="已提现金额">￥{{userInfo.tx_balance || 0}}</el-descriptions-item>
          </el-descriptions>
        </div>

        <div>
          <div class="el-dialog-header-box">
            <div class="dialog-title-block"></div>
            <span class="dialog-title-text">提现说明</span>
          </div>
          <el-divider />
        </div>
        <el-descriptions :column="2">
          <el-descriptions-item label="提现手续费">{{userInfo.config ? userInfo.config.rate : 0}}%</el-descriptions-item>
          <el-descriptions-item label="提现最小金额">{{userInfo.config ? userInfo.config.min_price : 0}}%</el-descriptions-item>
        </el-descriptions>

        <div>
          <div class="el-dialog-header-box">
            <div class="dialog-title-block"></div>
            <span class="dialog-title-text">提现提交</span>
          </div>
          <el-divider />
        </div>
        <el-form-item label="提现账户类型" prop="type">
          <el-radio-group v-model="form.type" style="width: 90%">
            <el-radio label="aly"> 支付宝</el-radio>
            <!-- <el-radio :label="2">银行卡</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提现金额" prop="price">
          <el-input v-model="form.price" placeholder="提现金额"></el-input>
        </el-form-item>

        <el-form-item label-width="50%">
          <el-button type="primary" @click="onSubmit('form')">提交</el-button>
        </el-form-item>
      </el-form>

      <!-- <div>
        <el-descriptions :column="2">
          <template slot="title">
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">到账银行卡</span>
            </div>
            <el-divider />
          </template>
          <el-descriptions-item label="开户账号">kooriookami</el-descriptions-item>
          <el-descriptions-item label="开户行姓名">18100000000</el-descriptions-item>
          <el-descriptions-item label="开户行名称">苏州市</el-descriptions-item>
          <el-descriptions-item label="开户行支行名称">
            <el-tag size="small">学校</el-tag>
          </el-descriptions-item>
        </el-descriptions>
      </div> -->

      <div>
        <el-descriptions :column="2">
          <template slot="title">
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">到账支付宝</span>
            </div>
            <el-divider />
          </template>
          <el-descriptions-item label="账号">{{zfbForm.code}}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{zfbForm.username}}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <!-- table区域-end -->

    <!-- 分页区域-begin -->
    <!-- 分页区域-end -->
  </div>
</template>
  
  <script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default({
  name: "balanceCashOut",
  data() {
    return {
      form: {
        type: "aly",
        price: "",
      },
      rules: {
        // type: [
        //   { required: true, message: '请输入银行卡号', trigger: 'blur' }
        // ],
        price: [
          { required: true, message: '请输入开户行', trigger: 'blur' }
        ],
      },
      loading: false,
      dialogVisible: false,
      disabled: false,

      zfbForm: {},
      userInfo: {},
    };
  },
  computed: {
    ...mapState(["user", "setting"]),
  },
  mounted() {
    let user = JSON.parse(localStorage.user);
    this.token = user.token;
    this.getInfo()
    this.getAccount()
    // setTimeout(() => {
    //   this.loading = false;
    // }, 1000);
  },
  methods: {
    ...mapMutations(["setuser", "setsetting"]),
    getechart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "核销券数",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // prettier-ignore
          data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45'],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
          axisPointer: {
            snap: true,
          },
        },
        series: [
          {
            smooth: true,
            data: [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              12,
              33,
              222,
              333,
              123,
              233,
              112,
              212,
              444,
              333,
              1,
              11,
              22,
            ],
            type: "line",
          },
        ],
      });
    },
    getautoshow() {
      axios
        .get(
          "http://shunyu.jnwww.top/admin.php?action=autoshow&method=ex_list",
          {
            params: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.msg == "登录已过期") {
            localStorage.removeItem("user");
            this.$router.replace("/");
            return;
          }
          res.data.data.data.forEach((item) => {
            this.options.push({
              label: item.exhibition_title,
              value: item.exhibition_id,
            });
            this.value = res.data.data.data[0].exhibition_id;
            this.getList();
          });
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getList() {
      axios
        .post(
          "http://shunyu.jnwww.top/admin.php?action=autoshow&method=tk_list",
          {
            exhibition_ids: this.value,
            begin_time: this.date[0],
            end_time: this.date[1],
            page_idx: this.page,
            page_size: 10,
            token: this.token,
          }
        )
        .then((res) => {
          this.tableData = res.data.data.data;
          if (res.data.data.data.length == 0) {
            this.empty = this.page;
            this.$message.error("已无数据");
          } else {
            this.empty = 10000000000000000;
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 jpg、png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleRemove(file) {
      this.form.imageUrl = "";
    },
    handlePictureCardPreview(file) {
      this.form.imageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
    },
    // 获取提现账号
	  getAccount() {
      this.loading = true
      this.$api.getAccount({}).then(
        res => {
          if(res.code == 'success'){
            this.zfbForm = res.data||{}
          }else{
            this.$message.error(res.msg)
          }
          this.loading = false
        }
      ).catch(err=>{
        this.$message.error(err.msg)
        this.loading = false
      })
    },
    // 余额提现
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            this.$api.balanceCashOut({...this.form}).then(
              res => {
                if(res.code == 'success'){
                  this.$message.success(res.msg)
                }else{
                  this.$message.error(res.msg)
                }
                this.loading = false
              }
            ).catch(err=>{
              this.$message.error(err.msg)
              this.loading = false
            })
          } else {
            return false;
          }
        });
       
    },
    // 获取个人信息（提现余额、提现说明）
    getInfo() {
      this.loading = true
      this.$api.getInfo({}).then(
        res => {
          if(res.code == 'success'){
            this.userInfo = res.data
          }else{
            this.$message.error(res.msg)
          }
          this.loading = false
        }
      ).catch(err=>{
        this.$message.error(err.msg)
        this.loading = false
      })
    },
    // 金额明细
    balanceInfo(id) {
      this.$router.push({path:'home/balanceDetail',query: {id}})
    },
  },
});
</script>
  
  <style scoped lang="less">
@import "../assets/less/easyElement.less";
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #949494;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 0 0 24px;
}
</style>
  